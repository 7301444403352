<template>
  <DefaultTemplate v-if="loading">
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/user' }"
            >จัดการข้อมูลผู้ประกอบการ</el-breadcrumb-item
          >
          <el-breadcrumb-item>ข้อมูลโครงการ</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-4">ข้อมูลโครงการ</p>
      </div>
    </div>
    <el-row :gutter="30">
      <el-col
        :span="24"
        v-for="(row, index) in dataProject"
        :key="index"
        class="mg-y-5 pos-relative"
      >
        <CardBlog
          :data="row"
          @morePicture="openDialogPicture"
          :page="`project`"
        />
      </el-col>
    </el-row>
    <el-dialog
      class="dialog-custom user-list"
      v-if="dialogPicture"
      :visible.sync="dialogPicture"
      width="70%"
      top="10vh"
      center
    >
      <img :src="imgMore" alt="" class="w-100" />
    </el-dialog>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import CardBlog from "@/components/card/CardBlog";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
    CardBlog
  },
  data() {
    return {
      loading: false,
      wordCredit: "",
      dataProject: [],
      imgMore: "",
      dialogPicture: false,
      juristicId: this.$route.params.id,
      criterionId: this.$route.params.criterionId
    };
  },
  mounted() {
    this.getCredit();
  },
  methods: {
    openDialogPicture(getImg) {
      this.imgMore = getImg;
      this.dialogPicture = true;
    },
    async getCredit() {
      const response = await HTTP.post(
        "user/interest/project",
        {
          juristicId: this.juristicId,
          criterionId: this.criterionId
        },
        { headers: { Authorization: `Bearer ${this.token}` } }
      ).catch(e => {
        return {
          data: {
            data: e,
            success: false
          }
        };
      });
      this.dataProject = response.data.obj;
      this.loading = true;
      return response;
    }
  },
  computed: {
    ...mapState({
      token: state => state._token
    })
  }
};
</script>
